.cp-hide-on-small-screen {
  display: flex;
}

@media screen and (width <= 800px) {
  .cp-hide-on-small-screen {
    display: none;
  }
}

.cp-profile-column {
  padding-right: 300px;
}

@media screen and (width <= 1024px) {
  .cp-profile-column {
    padding-right: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Jost, sans-serif;
  line-height: 1.3;
}

a:link, a:active, a:visited {
  text-decoration: none;
}

a {
  color: #61a391;
  text-decoration: none;
}

a:hover {
  color: #76d6ba;
  text-decoration: none;
}

input {
  font-size: 14px;
  font-weight: 400;
}

h1 {
  color: #0b3540;
  font-weight: 800;
}

h4 {
  font-weight: 800;
}

.display-2 {
  font-size: 80px;
  font-weight: 800;
}

input:focus {
  outline: 2px solid #61a391 !important;
}

#studio-title-hero-avatar {
  opacity: 0;
  animation: .5s forwards studio-title-hero-avatar;
  translate: 0 50px;
  scale: .9;
}

@keyframes studio-title-hero-avatar {
  to {
    opacity: 1;
    translate: 0;
    scale: 1;
  }
}

#studio-title-hero {
  opacity: 0;
  animation: .5s forwards studio-title-hero;
  translate: -50px;
}

@keyframes studio-title-hero {
  to {
    opacity: 1;
    translate: 0;
  }
}

#studio-title-hero-image {
  opacity: 0;
  background-position: 60% 60%;
  animation: .65s forwards studio-title-hero-image;
}

@keyframes studio-title-hero-image {
  to {
    opacity: 1;
    background-position: 50%;
  }
}

.dashboard-content {
  opacity: 0;
  animation: .75s forwards dashboard-content;
  translate: 0 50px;
}

@keyframes dashboard-content {
  to {
    opacity: 1;
    translate: 0;
  }
}
/*# sourceMappingURL=index.7fa4eab6.css.map */
