.cp-hide-on-small-screen {
    display: flex;
}

@media screen and (max-width: 800px) {
    .cp-hide-on-small-screen {
        display: none;
    }
}

.cp-profile-column {
    padding-right: 300;
}

@media screen and (max-width: 1024px) {
    .cp-profile-column {
        padding-right: 0;
    }
}

body {
    font-family: "Jost", sans-serif;
    padding: 0;
    margin: 0;
    line-height: 1.3;
}
a:link {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a {
    color: #61a391;
    text-decoration: none;
}
a:hover {
    color: #76d6ba;
    text-decoration: none;
}
input {
    font-weight: 400;
    font-size: 14;
}

h1 {
    color: #0b3540;
    font-weight: 800;
}
h4 {
    font-weight: 800;
}
.display-2 {
    font-size: 80;
    font-weight: 800;
}

input:focus {
    outline: 2px solid #61a391 !important;
}

#studio-title-hero-avatar {
    opacity: 0;
    scale: 0.9;
    translate: 0px 50px;
    animation: studio-title-hero-avatar 0.5s forwards;
}

@keyframes studio-title-hero-avatar {
    to {
        opacity: 1;
        translate: 0px 0px;
        scale: 1;
    }
}

#studio-title-hero {
    opacity: 0;
    translate: -50px 0px;
    animation: studio-title-hero 0.5s forwards;
}

@keyframes studio-title-hero {
    to {
        opacity: 1;
        translate: 0px 0px;
    }
}

#studio-title-hero-image {
    background-position: 60% 60%;
    animation: studio-title-hero-image 0.65s forwards;
    opacity: 0;
}

@keyframes studio-title-hero-image {
    to {
        opacity: 1;
        background-position: 50% 50%;
    }
}

.dashboard-content {
    translate: 0px 50px;
    opacity: 0;
    animation: dashboard-content 0.75s forwards;
}

@keyframes dashboard-content {
    to {
        translate: 0px 0px;
        opacity: 1;
    }
}
